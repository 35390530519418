@import "@skryv/core-ng1/theme/includes/index";
// Force top alignment since the alignment will be messed up if there are validation errors on the field
.perceel, .hectare, .teelt, .raming_schade_percentage {
  vertical-align: top;
  display: inline-block;
}

.perceel {
  width: 20%;
}

.teelt {
  width: 33%;
}

.hectare {
  width: 20%;
}

.raming_schade_percentage {
  width: 24%;
}