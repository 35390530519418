@import "@skryv/core-ng1/theme/includes/index";
// Force top alignment since the alignment will be messed up if there are validation errors on the field
.perceel, .teelt, .type_schade, .aantal, .eenheidsprijs {
  vertical-align: top;
  display: inline-block;
}

.perceel {
  width: 20%;
}

.teelt {
  width: 33%;
}

.type_schade {
  width: 20%;
}

.aantal {
  width: 11%;
}

.eenheidsprijs {
  width: 11%;
}