@import "@skryv/core-ng1/theme/includes/index";
vrf-document-summary {
  display: inline-flex;
  align-items: center;
  background-color: $toolbar-background-color;
  height: $footer-height;

  .dossier-summary {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

    padding: $base-spacing;
    color: $white;
    font-size: $smallest-font-size;
    font-weight: $font-weight-light;
  }
}