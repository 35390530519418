@import "@skryv/core-ng1/theme/includes/index";

$summary-circle: 10px;
$extended-summary-circle: 10px;
$progress-status: (
  closed: $orange,
  invalid-state: $red,
  open-empty: $blue,
  open-between: $yellow,
  open-full: $green
);

vrf-assign-expertise-summary {
  .summary {
    display: flex;
    color: $white;

    .summary-type-wrapper {
      display: flex;
      align-items: center;
      padding: 0 $base-spacing;
    }

    .summary-type-icon {
      font-size: 2*$base-font-size;
      margin-right: $base-spacing/3;
    }

    .summary-party-info {
      margin-left: $base-spacing/2;
      font-size: 0.8*$base-font-size;
      text-align: center;
      line-height: 0.8*$base-line-height;

      &.closed {
        color: lighten($toolbar-background-color-light,20%);
      }
    }

    .summary-party-status {
      width: $summary-circle;
      height: $summary-circle;
      border-radius: 50%;
      display: inline-block;

      @each $status,$color in $progress-status {
        &.#{$status} {
          background-color: $color;
        }
      }
    }
  }

  .extended-summary {
    z-index: $navigation-sidebar-z-index;
    background: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    transform-origin: 100% 100%;
    transform: scale(1, 0);
    transition: transform ease 250ms;

    position: fixed;
    left: 0;
    bottom: $footer-height;

    border-right: $base-border;
    border-top: $base-border;

    padding: 2*$base-spacing;

    display: flex;

    &.extended-summary-open {
      transform: scale(1, 1);
    }

    .extended-summary-type-wrapper {
      padding: 1.5*$base-spacing;
    }

    .extended-summary-type-icon {
      font-size: 3*$base-font-size;
      margin-bottom: $base-spacing/4;
    }

    .extended-summary-type-info {
      text-align: center;
      margin-bottom: 2*$base-spacing;
    }

    .extended-summary-type-assignment-size {
      font-size: $smallest-font-size;
    }

    .extended-summary-party-info {
      display: flex;
      vertical-align: bottom;

      th {
        border-color: transparent;
        padding: 0;
        font-weight: $font-weight-normal;
      }

      .closed {
        color: $gray;
      }

      .extended-summary-party-counts {
        font-size: $smallest-font-size;
        text-align: right;
      }
    }

    .extended-summary-party-status {
      width: $extended-summary-circle;
      height: $extended-summary-circle;
      border-radius: 50%;
      display: inline-block;

      margin-right: $base-spacing/2;
      margin-left: $base-spacing/2;
      margin-top: (($base-line-height * $base-font-size) - $extended-summary-circle)/2;

      @each $status,$color in $progress-status {
        &.#{$status} {
          background-color: $color;
        }
      }
    }
  }
}