@import "@skryv/core-ng1/theme/includes/index";
// Force top alignment since the alignment will be messed up if there are validation errors on the field
.rijksregisternummer, .naam {
  vertical-align: top;
  display: inline-block;
}

.rijksregisternummer {
  width: 25%;
}

.naam {
  width: 72%;
}