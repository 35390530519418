@import "@skryv/core-ng1/theme/includes/index";
.vrf-teelt-informatie {
  margin-left: $base-spacing;
  margin-top: -$base-spacing;
  margin-bottom: 2*$base-spacing;
  border: $base-border;
  border-color: $gray-light;

  table {
    width: 100%;

    th,td {
      border-bottom: 0;
      padding: 0 $base-spacing 0 $base-spacing/2;
      vertical-align: top;
    }

    th {
      &.totaal, &.afgekeurd {
        text-align: right;
      }

      > label {
        margin-bottom: 0;
        font-weight: $font-weight-light;
      }
    }

    .sub-table {
      margin: 0;
    }
  }
}